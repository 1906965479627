



.section-title {
text-align: center;
  margin: 3rem 6rem;
}
.section-title h2{
font-size: 2rem;
font-weight: 900;

}
.section-group-container p,.section-group-container-reverse p{
  padding: 1.5rem;
  border-radius: 20px;
}


.section-group-container h2, .section-group-container-reverse h2{
font-size: 2.2rem;

}


.section-group-container {
  z-index: -9999;
    display: flex;
    flex-direction: row;
justify-content: center;
background-color: black;
color: white;
padding: 3rem;
background-color: #DFDBE5;
background-color: black;
background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.606), rgba(0, 0, 0, 0.99)),url("../assets/images/background.jpg");
  }
.section-group-container-reverse {
  z-index: -9999;
    display: flex;
    flex-direction: row-reverse;
justify-content: center;
background-color: black;
color: white;
padding: 3rem;
background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.606), rgba(0, 0, 0, 0.97)),url("../assets/images/background.jpg");

}

.section-text {
  text-align: start;
  width: 40%;
  font-size: 1rem;
  margin: 3rem 2rem;
}
.section-text-reverse {
  text-align: start;
  width: 50%;
  font-size: 1rem;
  margin: 3rem 2rem;
}


@media screen and (max-width:1800px) {



  .section-text {

    width: 80%;

  }}
@media screen and (max-width:1600px) {



  .section-text-reverse {

    width: 70%;
 
  }}


@media screen and (max-width:1000px) {
  


  .section-group-container,  .section-group-container-reverse {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 0rem 0rem;
  }


  .section-text {
    width: 88%;

  }

  .section-img img {
    width: 20rem;

    object-fit: cover;
    margin-left: 0rem 0rem;

  }
  .section-text-reverse {
    width: 100%;
    width: 88%;

  }
/* 
  .section-img-reverse img {
    width: 50rem;
    height: 15rem;
    object-fit: cover;
    margin-left: 0rem 0rem;

  } */
}

@media screen and (max-width: 570px) {
  

  /* .section-img img {
 
    object-fit: cover;
    margin: 0rem 0rem 0rem 0rem;
  }
  .section-img-reverse img {

    object-fit: cover;
    margin: 0rem 0rem 0rem 0rem;
  } */

  .section-text-reverse, .section-text {
    padding: 0rem;
  }

  .section-img img, .section-img-reverse img {
    padding: 0rem;
  }
  

  .section-group-container h2, .section-group-container-reverse h2{
    font-size: 1.7em;
    margin-top: 1.3rem;
  }
  .section-group-container p, .section-group-container-reverse p{
    font-size: 1em;
    padding: 0.9rem;
  }
}
