/* Features.css */
.gradient-text {
  background: linear-gradient(
    to right,
    #4299e1,
    #667eea
  ); /* Adjust the colors as needed */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
/* Features.css */
.separator-line {
  display: inline-block;
  height: 1.6rem;
  border-left: 1px solid rgba(228, 228, 228, 0.636); /* Thin, faint line */
}

/* Hide separators on small screens */
@media (max-width: 639px) {
  .separator {
    display: none;
  }
  .separator-line {
    display: none; /* Hide on small screens */
  }
}
