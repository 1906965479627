
@media screen and (max-width: 700px) {
  .navbar {
      background-color: #ffffff;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 99999;
      position: fixed;
      width: 100%;
      position: fixed;
    }


    .menu-bars {
      margin-right: 2rem;
      font-size: 1.5rem;
      background: none;
      color: #000;
      display: block;
    }
  

  
    
    .nav-menu {
      background-color: #ffffff;
     
      height: 100vh;
      display: flex;
      justify-content: center;
      position: fixed;
      top: 0;
      right: -100%;
      transition: 350ms;
      z-index: 99999;
 
    } 
  
    .nav-menu.active {
      right: 0;
      transition: 250ms;
    }
    
    .nav-text {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 8px 0px 8px 16px;
      list-style: none;
      height: 60px;
    }
    
    .nav-text a {
      text-decoration: none;
      font-size: 18px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 16px;
      border-radius: 4px;
      gap: 0rem;
    }
    
    .nav-menu-items li:not(.navbar-toggle) {
      transition: background-color 0.3s, color 0.3s; /* Adding transitions for smooth effect */
  }
  
  .nav-menu-items li:not(.navbar-toggle):hover {
      background-color: #000000;
      color: #fff;
      width: 100%;
  }
    
    .nav-menu-items {
      width: 100%;
    }
    
    .navbar-toggle {
      background-color: #ffffff;
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    
    /* .contact-nav {
      margin-left: 16px;
    } */
  
    .nav-menu-items li.active {
      background-color: #000000;
      color: rgb(255, 255, 255);
    }}

    .logo-item {
      display: none;
    }


    @media screen and (max-width: 702px) {

      .nav-text a {
        width: 15rem;
      }
    }
    
    @media only screen and (min-width: 701px)  {

  

      .logo-item {
        display: block;
      }


.nav-menu-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
      background-color: white;
      z-index: 9999;
      position: fixed;
      width: 100%;

}

.nav-menu-items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}



.navbar-logo {
  display: none;
}
  
  .menu-bars {
display: none;
  }


  
  .nav-text {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    list-style: none;
    height: 70px;
  }
  
  .nav-text a {
    text-decoration: none;
    font-size: 16px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    gap: 0.5rem;
  }
  .nav-menu-items li:not(.navbar-toggle) {
    transition: background-color 0.3s, color 0.3s; /* Adding transitions for smooth effect */
}

.nav-menu-items li:not(.navbar-toggle):hover {
    background-color: #000000;
    color: #fff;
}

  
  .nav-menu-items {
    width: 100%;
  }
  
 

  .nav-menu-items li.active {
    background-color: #000000;
    color: rgb(255, 255, 255);
  }

}