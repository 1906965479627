@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}



